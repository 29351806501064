:root {
  --grey: #5a5a5a;
  --grey0: (0,100,200);
  --black: rgb(0,0,0);
  --white: rgb(255,255,255);
}

::selection {
  color: auto;
  background: none;
}

::-webkit-scrollbar {
  display: none;
}

@-moz-document url-prefix() {
  html,body{scrollbar-width: none; }
}

/* .clientLogin { display: none;} */

svg.on {
  
}

svg.off {
  display: none;
}

body {
  color: var(--black);
  /* font-family: "Gotham A", "Gotham B", sans-serif; */
  font-family: 'Gotham Narrow A', 'Gotham Narrow B', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  background: white;
  padding: 0;
  margin: 0;
}

.overlay {
  width: 100%;
  height: 100%;
}


#top.none.navSm  {
  background: rgba(16, 41, 76,0.2);
  backdrop-filter: blur(20px);
}

.white {
  background: white;
  color: var(--dark-blue);
}

.white a,
.white a:visited,
.white h2 {
  color: var(--dark-blue);
}

.app {
  width: 100%;
  height: 100%;
  float: left;
  padding: 0 0;
}

.app.menuOn {
  /* overflow: hidden; */
  /* height: 100vh; */
}

.page {
  float: left;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}

#core {
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  background: white;
  float: left;
  margin-bottom: 80vh;
  /* box-shadow: 0 10px 20px rgba(0,0,0,0.4); */
  padding-bottom: 4vw;
}


footer {
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 92vw;
  padding: 30vh 4vw 4vw 4vw;
  background: var(--black);
  /* background: linear-gradient(0deg, #1a1a1a 0%, #1a1a1a 50%, #5a5a5a 100%); */
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  gap: 50px;
  color: #fff;
}

footer .contactInfo {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 50px;
}

.copyright {
  position: fixed;
  bottom: 4vh;
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  /* height: 120px; */
  color: #fff;
}

.copyright a, footer a, footer .contactInfo a {
  color: #fff;
}

::-webkit-scrollbar {
  display: none;
}

h1 {
  font-size: 6.25vw;
  font-size: clamp(16px,3.75vw, 72px);
  margin: 0 0 0 0;
  line-height: 110%;
  font-weight: 500;
  font-family: "Gotham Narrow A", "Gotham Narrow B";
  color: var(--black);
}

h2 {
  font-size: clamp(16px,3.75vw, 72px);
  color: var(--black);
  /* letter-spacing: 5px; */
  /* letter-spacing: -20px; */
  margin: 0 0 0 0;
  padding: 0;
  font-weight: 500;
  line-height: 100%;
  font-family: "Gotham Narrow A", "Gotham Narrow B";
  /* font-family: "NeueHelvetica65Medium"; */
  /* font-family: "Gotham", sans-serif; */
}

.context {
  color: #fff;
  font-weight: 700;
  letter-spacing: 3px;
}

h3, #legalPage h2, .filterRow h4 {
  font-size: clamp(20px, 1.458vw, 28px);
  font-weight: 500;
  margin: 10px 0 15px 0;
  padding: 0;
  line-height: 120%;
  font-family: "Gotham Narrow A", "Gotham Narrow B";
}

.deliverableRow h3.alt, h3.alt, h3.alt p, h2.landing {
  font-size: clamp(20px, 1.458vw, 28px);
  font-weight: 300;
  line-height: 140%;
  padding: 10px 0 15px 0;
}

.heading h3.alt {
  margin: 0;
}

h4 {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 20px 0;
  padding: 0;
}

.ui {
  cursor: pointer;
}

.bioRow {
    /* padding-bottom: 100px; */
}


.bioRow p{
  margin: 0;
  padding: 0;
  
}

.bioRow .grid .item {
  display: grid;
  gap: 20px;
  grid-template-columns: 30% 1fr;
  align-items: center;
}

.bioRow .grid a.item span{
  /* border-bottom: 1px solid black; */
}

.bioRow .grid .item img {
  /* aspect-ratio: 1/1; */
  object-fit: cover;
  max-width: 160px;
  /* filter: contrast(130%); */
}

.bioLink {
  display: flex;
  gap: 20px;
  align-items: center;
}

a,
a:visited {
  color: var(--black);
  text-decoration: none;
}

a:focus {
  color: #333333;
}

footer .contactInfo a:focus, .mainMenu li a:focus {
  color: #dddddd;

}

a.borderBottom {
  border-bottom: 1px solid var(--black);
}

p, .bioRow h4, li {
  line-height: 160%;
  font-size: clamp(15px, 0.83333vw, 18px);
  font-weight: 400;
  font-family: "Gotham Narrow A", "Gotham Narrow B";
}

.bioRow h4, .bioRow p {
  margin: 0 0 0 0;
  padding: 0;
  line-height: 120%;
  font-size: clamp(20px, 1.458vw, 28px);
}

.bioRow h4 {
  font-weight: 500;
}

.translateY {
  transition: all 0.3s ease;
  display: block
}

.translateY:hover {
  transform: translateY(-2px);
}



/* Transitions */
/* 
.page {
  opacity: 0;
} */

/* #content {
  opacity: 0;
} */
.pageItem {
  opacity: 0;
}

.pageItem-enter {
  opacity: 0;
}

.pageItem-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease;
}
.pageItem-exit,
.pageItem-done,
.subPageItem-enter-done {
  opacity: 1;
}

.subPageItem-enter {
  opacity: 0;
}

.subPageItem-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease;
}
.subPageItem-exit,
.subPageItem-enter-done {
  opacity: 1;
}

.blue, .blue a {
  color: var(--black);
}

.blue path {
  stroke: var(--black);
}

/* Page */

#top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 3;
  height: 70px;
}

.logo {
  position: fixed;
  top: 40px;
  left: 4vw;
}

.logo path {
  /* fill: #fff; */
  fill: #000;
}

#logo {
  z-index: 4;
  position: absolute;
  top: 27px;
  
}


#logo svg {
  height: 32px;
  width: auto;
  opacity: 0;
  /* margin: 0 0 0 0;
  background: var(--white);
  padding: 16px 16px 16px 0; */
}

#logo_footer svg {
  height: 32px;
  width: auto;
}

.homeBanner {
  opacity: 1;
}

.homeTextBlock h1 .arBoxIn span.pipe {
  font-weight: 300;
  padding: 0 4px;
}

#logo path {
  transition: fill 0.3s ease;
}

#logo .default, #logo .fillBlack, #logo .fillWhite {
  opacity: 1;
}

#logo .default path {
  fill: black;
}
#logo .fillBlack path {
  fill: black;
}
#logo .fillWhite path {
  fill: white;
}

#logo_footer path {
  fill: white;
}


.menuOn #logo {
  position: fixed;
  top: 27px;
  left: 4vw;
}

.menuOn #logo path {
  fill: var(--white);
}


#top.navSm {
  top: 0;
  position: fixed;
  backdrop-filter: blur(2px);
}

.menuWrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: fixed;
  right: 0px;
  top: -1px;
  width: 100vw;
  float: left;
  height: 160px;
  overflow: hidden;
}

.menuOn .menuWrap {
  height: 100vh;
}

#signIn {
  margin: 0 0 0 20px;
  cursor: pointer;
  font-weight: 500;
}

#menuCornerBtn {
  cursor: pointer;
}

#menuBtn.on, #menuBtn_m.on {
  right: 0;
  top: -1px;
  position: fixed;
}

#menuBtn g{

}

#menuBtn path {
  opacity: 1;
}

#menuBtn path.off {
  opacity: 0;
}

.mainMenu {
  display: flex;
  width: 100vw;
  position: fixed;
  align-items: flex-start;
  justify-content: flex-end;
  opacity: 0;
  top: 160px;
  left: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

#menu {
  display: none;
}

.menuOn #menu {
  display: flex;
  z-index: 1;
}

.menuOn .mainMenu  {
  height: auto;
  overflow: visible;
}

footer .mainMenu {
  opacity: 1;
  height: auto;
  flex-direction: column;
  position: relative;
  width: auto;
  top: 0;
  justify-content: flex-start;
  overflow: visible;
  float: left; 
  width: 100%;
}

footer .mainMenu h3 {
  border-top: 1px solid #fff;
  padding: 0 0 50px 0;
  font-size: 50px;
  color: #fff;
  width: 100%;
}
footer .mainMenu nav {
  width: 100%;
}

.menuOn {
  height: calc(100vh - 200px);
}

nav ul, nav li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mainMenu nav {
  width: 66vw;
  padding-right: 4vw;
}

.mainMenu nav ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 50px;
  max-height: 450px;
}

.mainMenu ul li {
  border-top: 1px solid #fff;
  font-family: 'Gotham Narrow A', 'Gotham Narrow B', sans-serif;
  font-weight: 500;
  /* font-family: "Chronicle Display A", "Chronicle Display B"; */
  padding-top: 5px;
  font-size: 36px;
  color: #fff;
}

.mainMenu ul li ul li {
  font-weight: 400;
}

.mainMenu ul li.long {
  grid-row: span 6;
}



.mainMenu ul ul {
  grid-template-columns: 1fr;
  gap: 25px;
  margin-top: 25px;
}


.mainMenu ul ul li {
  border: none;
  font-family: "Gotham Narrow A", "Gotham Narrow B";
  font-size: 16px;
}

.mainMenu li a {
  color: #fff;
  width: 100%;
  display: block;
}

#menuCornerBtn, #menuCornerBtn_m {
  position: fixed;
  right: 0;
  top: 0;
  filter: drop-shadow(-2px 5px 8px rgba(0, 0, 0, 0.15));
  /* -webkit-filter: drop-shadow(-2px 5px 10px rgba(200, 0, 0, 0.5)); */
  /* transform: scale(0.75);
  transform-origin: top right; */

}

#dropShadow {
  /* display: none; */
  /* opacity: 0; */
}

/* #menuCornerBtn_m {
  filter: ;
} */

#menuOpen1, #menuOpen2, #menuOpen1_m, #menuOpen2_m {
  display: none;
}

.menuOn #top.none.navSm {
  background: none;
}

#content {
  padding-top: 100px;
}

.column {
  width: 92vw;
  padding: 0 4vw;
  float: left;
}

.row {
  float: left;
  width: 100%;
  padding-top: 200px;
}


.full {
  float: left;
  width: 100%;
}

.row#clientsRow {
  padding: 80px 0 0px 0;
}

#row0.row {
  padding-top: 200px;
}



.grid {
  display: grid;
}

.grid .item img {
  width: 100%;
  height: auto;
}

.grid_30_70 {
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  gap: 50px;
}

.grid_70_30 {
  display: grid;
  grid-template-columns: 0.7fr 0.3fr;
  gap: 50px;
}

.grid_70_30 ul {
  margin-top: 70px;
}

.grid_70_30 ul li {
  padding: 0;
  margin: 0 0 50px 0;
  font-weight: 800;
  list-style: square;
}

.grid_70_30 ul li span {
  /* font-family: "Gotham A", "Gotham B"; */
  color: var(--black);
}

.grid2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  width: 100%;
}

.grid3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  width: 100%;
}

.grid4 {
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
}

.grid5 {
  grid-template-columns: repeat(5, 1fr);
  gap: 50px;
}

.grid6 {
  grid-template-columns: repeat(6, 1fr);
  gap: 40px;
}

.logoContainer {
  /* min-height: 10vh; */
}
#logoContainer {

}

.logos {
  position: absolute;

}

.logoContainer.arBox {
  padding-top: calc(600 / 1600 * 100%);

}

.logoContainer .arBoxIn {
  align-items: flex-start
}

.grid.logos .item img {
  width: 90%;
  filter: contrast(200%);
  /* opacity: 0.5; */
}

.item.borderTop {
  border-top: 2px solid var(--black);
}

.heading {
  padding-bottom: 60px;
}

.heading.noPadding {
  padding-bottom: 0;
}


.heading.short {
  padding-bottom: 20px;
}

.heading.short h2 {
  padding-bottom: 0;
}

.heading h2 {
  padding-bottom: 20px;
  /* padding-bottom: 100px; */
}
.landing h1 {
  margin-bottom: 20px;
}

#caseIndex .heading h3 {
  /* margin-bottom: 40px; */
}

.bioRow .heading h2 {
  padding-bottom: 40px;
}

/* Aspect Ratios */

.arBox {
  height: 0;
  overflow: hidden;
  /* background: #fafafa; */
  position: relative;
  transition: background 0.3s ease;
  background: linear-gradient(90deg, rgba(250,250,250,0.5) 0%, rgba(200,200,200,0.5) 100%);
  animation: gradient 1.25s ease-in-out infinite;
  background-size: 400% 100%;
}

.arBox.on, .heading.landing h1.arBox.on {
  background: none;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

img {
  background: none;
  transition: all 0.3s ease;
}

img.loading {
  background: grey;
}

.loader {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: red;
}


.landing.heading {
  padding-bottom: 0;
  position: relative;
  /* top: -70px */
}

.heading.landing h1.arBox {
  padding-top: calc(40 / 1200 * 100%);
  background: none;
}

.homeTextBlock .heading.landing h1.arBox {
  padding-top: calc(60 / 1200 * 100%);
  margin-bottom: 0;
}

.homeTextBlock h1 {
  
}

.landing p.arBox {
  padding-top: calc(90/700 * 100%);
  background: none;
  width: 62%;
}

#home #landing {
  background: var(--black);
  aspect-ratio: 2.33;
}

#home .landing p.arBox {
  padding-top: calc(40/700 * 100%);
}

#home .landing.heading {
 left: -16px;
}

#caseIndex h3.arBox {
  padding-top: calc(25/800 * 100%);
}

#caseIndex h3.arBox.alt {
  padding-top: calc(45/800 * 100%);
}


#about .heading h1.arBox {
  padding-top: calc(115 / 1500 * 100%);
}

#about .heading h3.arBox {
  padding-top: calc(96/800 * 100%);
}
#about .heading h3.arBox.short {
  padding-top: calc(40/800 * 100%);
}

#caseIndex .landing p.arBox {
  padding-top: calc(20/700 * 100%);
}

.heading h1.arBox {
  padding-top: calc(65 / 1500 * 100%);
}


#caseContent .heading h1.arBox {
  padding-top: calc(65 / 1500 * 100%);
}

#caseContent .heading h2.arBox {
  padding-top: calc(73 / 1766 * 100%);

}

.caseRow .heading .arBoxIn {
  text-align: left;
  width: 100%;
  align-items: flex-start;
}

.heading h2.arBox {
  padding-top: calc(30 / 884 * 100%);
}

.heading h2.arBox.landing {
  padding-top: calc(70 / 884 * 100%);
  max-width: 81.2%;
}

.grid h3.arBox {
  padding-top: calc(20 / 310 * 100%);
}

.grid p.arBox {
  padding-top: calc(110/440 * 100%);
}

#contactPage h1 {
  padding-bottom: 60px;
}

#contactPage .grid h3.arBox {
  padding-top: calc(20 / 310 * 100%);
}

.bannerFile {
  width: 100%;
  float: left;

}

.bannerFile {
  aspect-ratio: 3.5/1;
}

#contactPage p .arBoxIn {
  flex-direction: column;
  gap: 40px;
}

.arBoxIn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  /* display: none; */
}

.arBoxIn img, #about .arBoxIn video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.grid p.arBox  .arBoxIn {
  align-items: flex-start;
}

.heading p, .heading h3 {
  max-width: 66.667%;
}

.heading h3 p {
  max-width: 100%;
  padding: 0;
  margin: 0 0 15px 0;
}

.item h3 {
  max-width: 100%;
}


.learn {
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: flex-start;
  margin-top: 20px;
}


#landing {
  position: relative;
  left: 0;
  width: 100vw;
  align-items: center;
}

.homeTextBlock {
  position: absolute;
  top: 15vw;
  opacity: 0;
}


.homeTextBlock h1 .arBoxIn span {
  background: var(--white);
  margin: 0 20px 0 0;
  padding: 8px 16px;
}

.homeTextBlock .pBox {
  background: var(--white);
  padding: 16px 16px;

  
}

.banners {
  position: relative;
  width: 100vw;
  aspect-ratio: 2.4;
}

.homeBanner {
  position: absolute;
  top: 0;
  height: auto;
  width: 100vw;
  aspect-ratio: 2.4;


}

.homeBanner .arBox {
  padding-top: 43%;
}


#landing .homeBanner .arBoxIn img, #landing .homeBanner .arBoxIn video {
  object-fit: cover;
  object-position: left;
  width: 100%;
  height: 100%;
  opacity: 0;
}

#landing .homeBanner.first .arBoxIn img, #landing .homeBanner.first .arBoxIn video {
  width: 100%; 
  opacity: 1;
}


#home .homeBanner {
  /* background: #EDEDED; */
  aspect-ratio: 2.4;

}
/* .homeBanner img {
  opacity: 0.2;
} */

/* Swiper */

.swiperColumn {
  margin-top: 100px;
  float: left;
  width: 100%;
}


.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  box-sizing: content-box;
}

.slide {
  display: flex;
  float: left;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 4vw);
  padding: 0 4vw;
  max-height: 760px;
  transition: all 0.3s ease;
  overflow: hidden;
}

.squareSlides .slide {
  aspect-ratio: 1/1;
}

.slide img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 800px;
  float: left;
  opacity: 1;
}



.swiper-button-next {
  z-index: 3;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0 !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
  background: rgba(16, 41, 76, 0.75);
  border: 1px solid #fff;
  border-radius: 40px;
  width: 50px !important;
  height: 50px !important;
  transition: all 0.3s ease;
  top: calc(50% - 4px) !important;
  opacity: 0;
}

 .pageReady .swiper-button-prev,
.pageReady .swiper-button-next {
  opacity: 1;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px !important;
  font-weight: 700;
}

.slide .textRow {
  padding: 15px 25px;
  height: 200px;
  float: left;
  width: calc(100% - 50px);
  transition: all 0.3s ease;

}

.slideInner {
  transition: transform 0.3s ease;
}

.squareSlides .slideInner img{
  aspect-ratio: 1/1;
}


.swiper-slide-active .textRow {
  transform: translateY(-230px);
}

.swiper-slide-active .slide img {
  opacity: 1;
}

.swiperNav {
  padding: 25px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
}

.swiperNext, .swiperPrev {
  cursor: pointer;
}

.swiperFraction.swiper-pagination-fraction {
  width: auto;
}

.swiper-button-disabled {
  opacity: 0.3;
}


/* Animations */

.animTrigger {
  position: relative;
  top: -5vh;
  /* border-top: 1px solid fuchsia; */
  height: 1px;
  width: 100vw;
  float: left;
  left: 0;
}

#row0trigger {
}

.anim0 {
  opacity: 0;
}

#landing .textBlock {
  z-index: 1;
  background: white;
}

#landing img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icon {
  padding-left: 20px;
}

.socialIcon {
  width: 32px;
  height: auto;
  float: left;
  padding: 16px 20px 0 0;

}




/* Case Index */
.gridCaseIndex {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(16, 0.5fr);
  gap: 50px;
  width: 100%;
  margin-top: 60px;
  float: left;
}

.grid .item.caseIndexBox {
  /* overflow: hidden; */
  grid-row: span 2;
  /* height: calc(46vw + 50px); */
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;

}

#caseIndex .animTrigger.shortBoxOdd {
  top: -30vh;
}

.item.caseIndexBox:nth-child(3) {
  grid-row: span 1;
  grid-column: 1;
  height: calc(23vw);
}

.item.caseIndexBox:nth-child(4) {
  grid-row: span 1;
  grid-column: 1;
  grid-row-start: 4;
  height: calc(23vw);
}

.item.caseIndexBox:nth-child(5) {
  grid-row-start: 3;
  grid-row-end: 5;
  grid-column: 2;
}

.item.caseIndexBox:nth-child(6) {
  grid-row-start: 5;
  grid-row-end: 7;
  grid-column: 1;
}

.item.caseIndexBox:nth-child(7) {
  grid-row-start: 5;
  grid-row-end: 7;
  grid-column: 2;
}

.item.caseIndexBox:nth-child(8) {
  grid-row-start: 7;
  grid-row-end: 9;
  grid-column: 1;
}

.item.caseIndexBox:nth-child(9) {
  grid-row: span 1;
  grid-column: 2;
  grid-row-start: 7;
  height: calc(23vw);
}

.item.caseIndexBox:nth-child(10) {
  grid-row: span 1;
  grid-column: 2;
  grid-row-start: 8;
  height: calc(23vw);
}

.item.caseIndexBox:nth-child(11) {
  grid-row-start: 9;
  grid-row-end: 11;
  grid-column: 1;
}

.item.caseIndexBox:nth-child(12) {
  grid-row-start: 9;
  grid-row-end: 11;
  grid-column: 2;
}

.item.caseIndexBox:nth-child(13) {
  grid-row-start: 11;
  grid-row-end: 13;
  grid-column: 1;
}

.item.caseIndexBox:nth-child(14) {
  grid-row-start: 11;
  grid-row-end: 13;
  grid-column: 2;
}

.item.caseIndexBox:nth-child(15) {
  grid-row: span 1;
  grid-column: 1;
  grid-row-start: 13;
  height: calc(23vw);
}

.item.caseIndexBox:nth-child(16) {
  grid-row: span 1;
  grid-column: 1;
  grid-row-start: 14;
  height: calc(23vw);
}

.item.caseIndexBox:nth-child(17) {
  grid-row-start: 13;
  grid-row-end: 15;
  grid-column: 2;
}

.item.caseIndexBox:nth-child(18) {
  grid-row-start: 15;
  grid-row-end: 17;
  grid-column: 1;
}

#caseIndex .arBox {
  background: none;
}

.grid .item.caseIndexBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: all 0.3s ease;
}

 .caseIndexBoxIn {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: all 0.3s ease;
 }

 .grid .item.caseIndexBox:hover img {
  /* opacity: 0.4; */
 }

.overlay {
  background: rgba(0,0,255,0.5);
  position: absolute;
}

#caseIndex .textRow {
  padding: 50px;
  height: 350px;
  float: left;
  width: calc(100% - 100px);
  transition: all 0.3s ease;
}

#caseIndex .shortBox .textRow {
  padding: 50px 50px 0 50px;
  /* height: calc(23vw - 100px); */
  height: 100%;
  float: left;
  width: 50%;
  margin-top: calc(-50% - 20px);
  transition: all 0.3s ease;
  transform: translateX(-100%);
}

.grid .item.caseIndexBox:hover .caseIndexBoxIn .textRow{
  transform: translateY(-350px);
}

#caseIndex .shortBox.shortBox:hover .caseIndexBoxIn .textRow{
  /* transform: translateX(28vw); */
  transform: translateX(0);
}

.grid .item.caseIndexBox.shortBox:hover img{

  /* opacity: 0.4;   */
}

/* 
.learn {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.learn:hover {
  opacity: 1;
} */

/* Case Detail */

#caseContent {
  /* margin-top: -152px; */
  margin-top: -1px;
}

#caseContent #row0.row {
  padding-top: 0;
}


#caseIndex .heading.landing h1.arBox {
  padding-top: calc(115 / 1500 * 100%);
  padding-bottom: 20px;
}

.heading h2.caseHeading.arBox {
  /* padding-top: calc(200/800 * 100%); */
 
}
.caseHeading.arBox .arBoxIn {
  width: 100%;
}


.caseRow .heading {
  width: 100%;
}


.caseRow.arBox {
  padding-top: calc(400/800 * 100%);
  background: none;
  width: 100%;
}

.caseRow.arBox.wHeading {
  padding-top: calc(500/800 * 100%);
}


.caseRow .arBoxIn {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}


h3.arBox {
  padding-top: calc(50/800 * 100%); 
}


.flex {
  display: flex;
  gap: 40px;
}

.bioRow .flex {
  gap: 40px;
  flex-direction: column;
}

.items {
  display: grid;
}

.items1 {
  grid-template-columns: 1fr;
}

.items2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.items3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.items4 {
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.items12 {
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
}


.textGrid {
  grid-template-columns: 66% 33%;
}

.caseRowBox .grid4 {
  gap: 40px;
}

.items img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.items.textGrid img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.caseRowItemText {
  padding: 0 0 0 40px;
  display: flex;
  align-items: center;
}


.items video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.caseRowBox {
  float: left;
  width: 100%;
  padding: 20px 0;
}

.caseRowBox.sectionRow {
  padding: 200px 0 20px 0;
}

.row.caseRow {
  padding-top: 0;
}
/* .caseRow h2 {
  padding-bottom: 
} */

.itemArBox {
  height: 0;
  overflow: hidden;
  /* background: #fafafa; */
  position: relative;
  transition: background 0.3s ease;
  background: linear-gradient(90deg, rgba(250,250,250,0.5) 0%, rgba(200,200,200,0.5) 100%);
  animation: gradient 1.25s ease-in-out infinite;
  background-size: 400% 100%;
}

.itemArBox.loaded {
  background: none;
}

.itemArBoxIn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  /* display: none; */
}

.itemArBox img {
  background: none;
}

.caseHeadingRow .column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}


.caseHeadingRow .heading.row0 {
  padding: 200px 0 20px 0;
  float: left;
  width: 100%;
}

#caseContent h2, #caseContent h1 {
  /* font-size: 4.25vw; */
  font-size: clamp(28px,3.75vw, 72px);
  width: 100%;
  text-align: left;
  padding: 0;
}

#caseContent h3.arBox {
  padding-top: calc(22/800 * 100%);
  margin: 0 0 10px 0;
}

.caseHeadingRow h1 {
  font-size: clamp(16px,3.75vw, 72px);
  width: 100%;
  text-align: left;
}

.caseHeadingRow video, .caseHeadingRow img {
height: 100%;
width: 100%;
object-fit: cover;
}

.caseHeadingRow.column {
display: flex;
flex-direction: column;
justify-content: flex-start;
}

/* Swiper */
.swiperColumn {
  margin-top: 0;
}

.swiper-slide img {
  width: 100%;
  height: auto;
}

.swiperCaption {
  position: relative;
  z-index: 2;
  top: 100%;
  margin-top: -64px;
  float: right;

  height: 60px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: white;
  color: var(--black);
  cursor: pointer;
}

.captionText {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 0;
  padding: 0;
  overflow: hidden;
  transition: width 0.3s ease;
}

.captionText p {
  opacity: 0;

}

.plusLine {
  opacity: 1;
}

.open .plusLine {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.swiperCaption.open .captionText {
  width: 40vw;
  padding: 10px 20px;
  overflow: auto;
}

.swiperCaption.open .captionText p {
  opacity: 1;
  transition: opacity 0.3s ease 0.2s;
}

.captionBtn {
  float: right;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
 
}

.swiperCaption > * {
 pointer-events: none;
}

.deliverableRow h3 {
  border-top: 2px solid var(--black);
  /* margin-top: 40px; */
  line-height: 200%;
  margin-top: 0;
}

.serviceRowImage {
  border-top: 2px solid var(--black);
}

.deliverableImage {
  aspect-ratio: 4/3;

}
/* 
.deliverableRow .serviceRowImgContainer h3 {
  border-top: none;
  padding: 0;
} */

.deliverableImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  float: left;
}

.serviceRowImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  float: left;
}


.serviceRowImage {
  width: 100%;
  aspect-ratio: 3;
}

/* Scroll */

.caseTrigger {
  position: absolute;
}



/* WCAG 2.1 */

a.skip-main {
	left:-999px;
	position:absolute;
	top:auto;
	width:1px;
	height:1px;
	overflow:hidden;
	z-index:-999;
  }
  a.skip-main:focus, a.skip-main:active {
	color: #fff;
	background-color:#000;
	left: auto;
	top: auto;
	width: 30%;
	height: auto;
	overflow:auto;
	margin: 10px 35%;
	padding:5px;
	border-radius: 15px;
	border:4px solid yellow;
	text-align:center;
	font-size:1.2em;
	z-index:999;
  }


#contactPage {
  height: 100vh;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

#signIn {
  height: 100vh;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}
  

/* Sign In */

.signInForm {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  gap: 20px;
}

.loginForm input {
  border: 1px solid black;
  -webkit-appearance: none;
  outline: none;
  background: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  padding: 10px 20px;
  font-size: 18px;
}


.loginForm input:active, .loginForm input::placeholder  {
  background: #ffffff;
}

.loginForm input:focus {
  outline: none;
}

.loginForm .btn span{
  font-size: 18px;
  border-bottom: 2px solid black;
  float: left;
  width: auto;
}

/* Work Index */

#workIndex h1 {
  padding-bottom: 30px;
    padding-top: 40px;
}

.filterRow {
  float: left;
  width: 100%;
  padding: 0;
}

.filterCols.closed {
  opacity: 0;
}


.filterCols {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  position: absolute;
  width: 92%;
  padding-left: 1%;
  margin-top: 70px;
  opacity: 0;
  height: 0;
}

.filterCol {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* border-top: 2px solid black; */
}

.filterOn {
  /* opacity: 0; */
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 40px;
  width: auto;
  float: left;
  z-index: 3;
  position: relative;
  transition: all 0.2s ease;
}

.filterOn.active {
  background: rgba(0,0,0,0.05);
}

.filterOn h3{
  /* font-weight: 500; */
  /* border-bottom: 2px solid black; */
  width: auto;
  float: left;
  position: relative;
  z-index: 2;
  font-size: 18px;
  margin: 0;
  padding: 0;
  /* border: 1px solid black;
  border-radius: 40px;
  padding: 10px 20px; */
}
.search {
  background: #fff;
  border-radius: 40px;
  /* border: 1px solid #ddd; */
  float: left;
  width: 92%;
  margin-bottom: 40px;
  position: absolute;
  left: 4%;
  
  /* background: white; */
  z-index: 4;
  box-shadow: 0 3px 10px rgba(0,0,0,0.2);
}

.search input{
  -webkit-appearance: none;
  padding: 20px 40px;
  font-size: 20px;
  font-family: "Gotham Narrow A", "Gotham Narrow B";
  border: none;
  width: calc(100% - 80px);
  background: none;
}

.search input:focus {
  outline: none;
}

.search input::selection {
  background: black;
  color: white;
}

.results {
  float: left;
  width: 100%;
  padding: 20px 40px 40px 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.filterBox {
  float: left;
  width: 100%;
  margin-top: 100px;
  /* position: absolute; */
}

.gridWorkIndex {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 50px;
  width: 100%;
  position: relative;
  float: left;
  margin-top: 40px;
  opacity: 0;
  /* padding-bottom: 300px; */
}


.workIndexBox {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
}

.gridWorkRows {
  display: flex;
  flex-direction: column;
  float: left;
}

.gridWorkRows img{
  width: 100%;
  height: auto;
  float: left;
}


#workIndex .caseIndexBoxIn {
  /* object-fit: cover; */
  float: left;

}



#workIndex .grid .item img {
  aspect-ratio: 16/10;
  object-fit: cover;
  float: left;
  width: 100%;
  /* object-position: center top; */
}


#workIndex .grid .textRow {
  border-top: 2px solid black;
  float: left;
  width: 100%;
}

#workIndex h3.arBox {
  padding-top: 3.125%;
}

/* Work Detail */

#workDetail h3.arBox.alt {
  overflow: visible;
}

#workDetail h3.arBox.alt .arBoxIn {
  flex-direction: column;
  text-align: left;
  width: 100%;
  justify-content: flex-start;
}

.relatedProjects {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.relatedProjectLinks {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.relatedProjectLinks h3.alt {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0;
  margin: 0;

 
}

.relatedProjectLinks h3.alt svg {
  width: 16px;
  height: 16px;
}

.crumbs ul {
  display: flex;
  gap: 20px;
  margin: 0 0 60px 0;
  padding: 0;
}

.crumbs ul li {
  list-style: none;
  color: #aaa;
  
}

/* .crumbs .noLink {
  color: #aaa;
} */

#workDetail .heading.landing h1.arBox {
  padding-top: calc(100 / 1200 * 100%)
}

#workDetail .gridWorkRows {
  padding-top: 0px;
  gap: 30px;
  /* flex-flow: row wrap; */
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  float: left;
  width: 100%;
  /* flex-wrap: wrap; */
}

#workDetail .landing h1 {
  margin-bottom: 0;
}

#workDetail .file.full, .swiperColumn.full {
  width: 100%;
  float: left;
  grid-column: span 4;
}

#workDetail .file.half, #workDetail .swiperColumn.half {
  grid-column: span 2;
  /* width: 50%; */
  /* flex-shrink: 4; */
  /* float: left; */
}

.pagination {
  display: flex;
  gap: 20px;
  width: 100%;
  float: left;
  margin: 100px 0 0 0;
  justify-content: space-between;
}

.pageBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  
}

.arrowLeft {
  transform: scale(-1, 1);
}


/* Login */

#loginFormPage {
  width: 100vw;
  height: calc(100vh - 90px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* background: var(--dar); */
}


#loginFormPage h1 {
  padding: 0 0 10px 0;
  /* padding: 10px 0;
  font-weight: 200 */
  font-size: 40px;
}

#logInLogo svg {
  width: 100%;
  height: auto;
}

#logInLogo svg path {
  fill: white;
}

.loginForm {
width: 100%;
/* height: 100%; */
display: flex;
align-items: center;
justify-content: flex-start;
flex-direction: column;
max-width: 400px;
/* max-height: 400px; */
gap: 25px;
border: 1px solid var(--white-20);
border-radius: 20px;
/* background: var(--mid-grey3); */
box-shadow: 0 12px 20px 0px rgba(0, 0, 0, 0.3); 
padding: 80px 0;
background-color: white;
position: relative;
}

.loginForm .formField {
  width: 61%;
}

.loginForm input {
width: calc(100% - 8px);
margin: 0;
padding: 4px;
border-radius: 4px;
border: 2px solid black;
font-size: 16px;
}

.loginForm input.submitBtn {
width: 100%;
/* border: 1px solid var(--light-blue2); */
background: black;
color: white;
padding: 10px 0;
transition: all 0.3s ease;
cursor: pointer;
margin: 20px 0 0 0;
-webkit-box-shadow: 0 0 0px 1000px #000000 inset !important;
}

.loginForm input.submitBtn:hover {
 transform: translateY(-3px);
}

@media only screen and (max-width: 1440px) {

  /* h1 {
    font-size: 90px;
  } */

}

@media only screen and (max-width: 1440px) {

  /* h1 {
    font-size: 90px;
  } */

}


@media only screen and (max-width: 1024px) {
  #logo, .menuOn #logo {
    top: 17px
  }
  #logo svg {
    height: 24px;
  }

  h1,
  nav ul,
  nav ul li,
  #detail h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 32px;
  }

  h3, .item h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }


  p {
    font-size: 14px;
  }

  .heading {
    padding-bottom: 10px;
  }


  .heading p, .landing p.arBox {
    max-width: 100%;
    width: 100%;
  }

  #core {
    margin-bottom: 70vh;
  }

  .mainMenu nav {
    width: 92vw;
    padding-left: 0vw;
  }

  .grid3,.grid_70_30, .mainMenu nav ul, footer {
    grid-template-columns: 100%;
  }

  footer {
    min-height: 100vh;
    padding-top: 40vh;
  }

  .menuOn .menuWrap {
    height: 100vh;
    overflow-y: scroll;
    background: var(--black);
  }

  .menuOn .mainMenu {
    height: 72vh;
    overflow-y: scroll;
  }

  .mainMenu nav ul {
    /* grid-template-rows: 100%; */
  }

  #row0.row, .caseHeadingRow .heading.row0 {
    padding-top: 50px;
  }

  .caseRowBox.sectionRow {
    padding-top: 60px;
    padding-bottom: 10px;
  }



  .grid3 {
    gap: 40px
  }

  .grid4 {
    grid-template-columns: repeat(2, 1fr);
  }

  h3.serviceRowImage1 {
    border: none;
    margin: 0;
  }

  .deliverableRow h3.alt {
    /* padding-bottom: 0;
    margin-bottom: 0; */
  }
  
  .deliverableRow .grid_30_70 {
    gap: 0;
  }

  /* Aspect Ratio Boxes */

  .homeTextBlock .heading.landing h1.arBox {
    padding-top: calc(60 / 800 * 100%);
    margin-bottom: 0;
  }

  .homeBanner .arBox {
    padding-top: 43%;
  }

  .homeTextBlock {
    top: 8vh;
  }

  

  .heading h2.arBox.landing {
    padding-top: 16%;
    max-width: 100%;

  }

  .bioRow .grid .item{
    grid-template-columns: 1fr 1fr;
  }
  .bioRow .flex {
    gap: 20px;
  }

  #home .landing.heading {
    left: 0;
  }

  .heading.landing h1.arBox {
    padding-top: calc(40 / 360 * 100%)
  }

  #caseIndex .landing p.arBox {
    padding-top: calc(80/360 * 100%);
  }

  #caseIndex h3.arBox.alt {
    padding-top: calc(80/800 * 100%);
  }

  .heading h2.arBox {
    padding-top: calc(30/360 * 100%);
  }

  
  .grid p.arBox {
    padding-top: calc(140/360 * 100%);
  }

  
  
  #contactPage {
    height: auto;
  }

  .caseRowItemText {
    padding: 0;
  }

  .caseRowItemText p {
    margin-top: 0;
  }

  nav.sub .swiper-wrapper{
    scroll-snap-type: none !important;
    padding-left: 5%;
  }

  .heading p, .heading h3 {
    max-width: 100%;
  }

  /* Menu */

  .mainMenu {
    top: 120px;
  }

  
  .mainMenu nav ul {
    grid-template-rows: auto;
  }

  .mainMenu ul li {
    font-size: 26px;
  }

  .menuWrap {
    height: 100px;
  }

  #content, #about, #contactPage, #caseIndex, #legalPage {
    padding-top: 100px;
  }


  .gridCaseIndex {
    gap: 20px;
  }

  /* Aspect Ratios */


  #about .heading h1.arBox {
    padding-top: calc(115 / 1500 * 100%);
  }
  
  #about .heading h3.arBox {
    padding-top: calc(600/800 * 100%);
  }
  
  #about .heading h3.arBox.short {
    padding-top: calc(40/800 * 100%);
  }

  h3.arBox {
    padding-top: calc(100/800 * 100%);
  }

  #caseIndex .heading.landing h1.arBox {
    padding-bottom: 20px;
  }

  #caseIndex h3.arBox {
    padding-top: calc(40/800 * 100%);
  }

  


  /* General */

  .row {
    padding-top: 60px;
  }


  /* Case Detail Mobile */

  nav.sub .swiper-wrapper{
    scroll-snap-type: none !important;
    padding-left: 5%;
  }
  /* Aspect Ratios */
  

  h3.arBox {
    padding-top: calc(140/800 * 100%);
  }

  .logoContainer.arBox {
    padding-top: 150%;
  }
  
  .caseRowBox {
      padding: 5px 0;
  }
  .items1 {
    gap: 40px;
  }
  
  .textGrid {
      grid-template-columns: 1fr;
      gap: 0px;
  }
  .grid2 {
    grid-template-columns: 1fr;
    gap: 20px;
    width: 100%;
  }

  .grid_30_70 {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .grid_70_30 ul {
    margin-top: 0;

  }

  .grid6 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 10px;
  }

  .swiper-slide-active .textRow {
    transform: none;
  }

  .slideInner .textRow p {
    display: none;
  }

  .squareSlides .slide {
    aspect-ratio: 1/1.5;
  }


  .gridCaseIndex {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    /* grid-template-columns: 1fr;
    grid-template-rows: repeat(32, 1fr); */
    row-gap: 10px;
    column-gap: 0;
  }
  .grid .item.caseIndexBox {
    grid-row: auto / auto;
    aspect-ratio: 1/1.3;
    align-items: flex-start;
  }

  .gridCaseIndex .arBoxIn {
    align-items: flex-start;
  }

  .caseIndexBoxIn {
    object-fit: none;
    height: auto;
  }

  .grid .item.caseIndexBox img {
    aspect-ratio: 1/1;
  }

  .item.caseIndexBox {
    height: auto !important;
  }

  #caseIndex .textRow, #caseIndex .shortBox .textRow {
    height: auto;
    padding: 20px;
    width: calc(100% - 40px);
    margin: 0;
    transform: translateX(0);
  }

  

  .homeTextBlock h1 {
    font-size: 30px;
  }

  footer .mainMenu nav {
    display: none;
  }

  .bioRow .heading {
    padding-bottom: 30px;
  }

  .serviceRowImage {
    border-top: none;
  }
  
}

@media only screen and (max-width: 600px) {


  .mainMenu ul ul.subNav {
    height: 0;
    overflow: hidden;
    margin: 0;
  }

  .mainMenu .open .subNav {
    height: auto;
    margin: 25px 0 0 0;
  }

  .mainMenu ul li.long {
    grid-row: span 1;
  }

  .mainMenu ul li.socials {
    border: none;
    padding-top: 50px;
  }

  .mainMenu ul li.long.open {
    grid-row: span 10;
  }

  .subNavBtn {
    display: flex;
    gap: 20px;
  }

  .minus {
    display: none;
  }

  .plus {
    display: block;
  }

  .caret {
    transform: scale(1,1);
    /* transform: rotate(0deg); */
    transition: transform 0.3s ease;
  }

  .open .caret {
    transform: scale(-1,-1);
    /* transform: rotate(-180deg); */
  
  } 




  #home .homeBanner, #home #landing, #home .banners {
    aspect-ratio: 1/1;
  }


  .homeTextBlock .heading.landing h1.arBox {
    padding-top: calc(310 / 1200 * 100%);
    margin-bottom: 0;
    line-height: 100%;
  }

  .heading h2.arBox.landing {
    padding-top: 50%;
  }

  .homeBanner .arBox {
    padding-top: 100%;
  }

  .homeTextBlock {
    top: 70px;
  }

  .homeTextBlock h1 .arBoxIn span {
    padding: 0;
    margin: 0;
    background: var(--black);
    color: #fff;
  }

  
  .homeTextBlock .pBox {
    padding: 0;
    margin: 0;
    background: var(--black);
    color: #fff;
  }

  .homeTextBlock .arBoxIn {
    flex-wrap: wrap;
    column-gap: 10px;
  }

  .serviceRowImage {
    aspect-ratio: 1.4;
  }
  .grid .item.caseIndexBox {
    grid-row: auto / auto;
    aspect-ratio: 1/1.7;
    align-items: flex-start;
  }
  #caseContent .bannerFile {
    aspect-ratio: 1/1;
  }

  #caseContent .heading h1.arBox {
    padding-top: calc(60 / 360 * 100%);
  }

  #caseContent .heading h2.arBox {
    padding-top: calc(60 / 360 * 100%);
}

  #caseContent h3.arBox {
    padding-top: calc(60/360 * 100%);
  }

  #caseIndex h3.arBox.alt {
    padding-top: calc(180/800 * 100%);
  }
  

  .items2 {
    grid-template-columns: 1fr;
    gap: 20px;
    width: 92vw;
}

  .copyright {
    position: relative;
  }

  .filterCols {
    grid-template-columns: 1fr;
   
    background-color: var(--white);
    padding-bottom: 60px;
    width: 96%;
    padding-left: 4%;
    left: 0;
    box-shadow: 0 10px 10px rgba(0,0,0,0.2);

  }

  .filterCols.active {
    z-index: 4;
  }

  .gridWorkIndex {
    grid-template-columns: 1fr;
    gap: 20px;

  }

  #workDetail .heading.landing h1.arBox {
    padding-top: calc(180 / 600 * 100%);;
  }

  #workDetail, #workIndex {
    margin-top: 50px;
  }

  .search {
    /* border-radius: 15px; */

  }
  .search input {
    padding: 20px;
  }

  .results {
    float: left;
    width: calc(100% - 40px);
    padding: 20px 20px 30px 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .loginForm {
    width: 90%;
    padding: 40px 0 60px 0;
  }

  .relatedProjects {
    padding-bottom: 60px;
  }

  #workDetail .gridWorkRows {
    grid-template-columns: 1fr;
    display: flex;
  }



  #workDetail .file.full, #workDetail .swiperColumn.full, #workDetail .swiperColumn.half, #workDetail .file.half {
    grid-column: span 1;

  }

  .relatedProjectLinks h3 {
    font-size: 14px;
    font-weight: 300;
    line-height: 140%;
    padding: 10px 0 15px 0;
  }

  .relatedProjectLinks h3 svg {
    width: 14px;
    height: 14px;
  }


}




@media only screen and (orientation: landscape) and (max-width: 1200px) {


  .swiper-wrapper {
    padding: 0;
  }

  .mainMenu {
    top: 100px;
  }
  .heading h2.arBox.landing {
    padding-top: calc(100 / 884 * 100%);
    max-width: 81.2%;
}



}




@media only screen and (max-width: 375px) {


}